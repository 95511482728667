/* eslint-disable no-unused-vars */
import {useState, useLayoutEffect, useRef} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useLocation, useNavigate, Link} from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import api from '../../../../services/api';
import {useQuery} from '../../../../hooks/useQuery';
import {useAuth} from '../../hooks/useAuth';

import Input from '../../../../components/_form/Input';
import MaskedInput from '../../../../components/_form/MaskedInput';
import Button from '../../../../components/Button';
import {LoadingBox} from '../../../../components/LoadingBox';
import {validateWhiteSpaces, validateUppercase} from '../../../../utils/utils';

import logo from '../../../../assets/images/logos-inline-color.png';
import worker1 from '../../../../assets/images/worker1.jpeg';

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useQuery(location.search);
  const comeFromLP = queryParams.get('lp');

  const {signUp} = useAuth();
  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: {errors},
  } = useForm({
    defaultValues: {
      name: queryParams.get('name'),
      email: queryParams.get('email'),
    },
  });

  const recaptcha = useRef();
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState({
    loading: true,
    data: {},
  });

  const canUseEmail = async (email) => {
    try {
      if (email.length < 5) return false;

      const {data} = await api.post('/validations', {
        type: 'full_email',
        value: email,
      });

      if (data.hasValue) return false;

      return true;
    } catch (_) {
      return false;
    }
  };

  const canUseUsername = async (value) => {
    try {
      if (value.length < 5) return false;

      const {data} = await api.post('/validations', {
        type: 'username',
        value,
      });

      if (data.hasValue) return false;

      return true;
    } catch (_) {
      return false;
    }
  };

  const onSubmit = async (data) => {
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      // eslint-disable-next-line no-alert
      alert('Please verify the reCAPTCHA!');
      return;
    }

    try {
      setLoading(true);
      const payload = {
        ...data,
        status: 'active',
        role: 'customer',
        plan_id: plan.data._id,
        plan_stripe_id: plan.data.stripe_id,
        sale_id: queryParams.get('sid') ? queryParams.get('sid') : '',
        trial: true,
        language: 'en',
      };
      await api.post('/subscription', payload);

      await signUp(payload);
    } catch (error) {
      if (
        error.response.data &&
        error.response.data.err &&
        error.response.data.err.errors
      ) {
        const errorsKeys = Object.keys(error.response.data.err.errors);
        errorsKeys.forEach((k) => {
          const errorObj = error.response.data.err.errors[k];
          const field = errorObj.path;
          const message = errorObj.message.replace('Path', 'Field');
          setError(field, {
            type: 'manual',
            message,
          });
        });
      }

      if (
        error.response.data &&
        error.response.data.err &&
        error.response.data.err.data &&
        error.response.data.err.data.param
      ) {
        let field = error.response.data.err.data.param;

        if (
          error.response.data.err.data.param === 'exp_year' ||
          error.response.data.err.dataparam === 'exp_month'
        ) {
          field = 'expiry';
        }
        if (error.response.data.err.data.param === 'number') {
          field = 'card_number';
        }

        setError(field, {
          type: 'manual',
          message: error.response.data.err.data.msg || 'invalid data',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    async function fetchPlan() {
      try {
        let planParam = queryParams.get('p');
        if (
          !planParam ||
          !['workpodium', 'workpodium-yearly'].includes(planParam)
        ) {
          planParam = 'workpodium';
        }
        const {data} = await api.get(`/plans/${planParam}`);
        setPlan({loading: false, data});
      } catch (error) {
        navigate('/new-account?p=workpodium');
      }
    }

    fetchPlan();

    window.recaptchaOptions = {
      enterprise: true,
    };
  }, []);

  return (
    <>
      <div className="relative bg-white lg:h-screen lg:overflow-hidden">
        <div className="lg:h-full w-full lg:w-7/12">
          <div className="flex flex-col items-center justify-center w-full h-full p-4 md:p-8">
            <img
              className="mx-auto h-12 w-auto mb-4"
              src={logo}
              alt="WorkPodium - Logo"
            />
            {comeFromLP ? (
              <h1 className="text-2xl font-extralight text-center lg:text-4xl">
                Complete your{' '}
                <span className="font-bold text-yellow-500">registration</span>{' '}
                and <br />
                see the difference <span className="font-bold">
                  WorkPodium
                </span>{' '}
                makes.
              </h1>
            ) : (
              <h1 className="text-2xl font-extralight text-center lg:text-4xl">
                Start your <span className="font-bold">free trial</span> today!{' '}
                <br />
                See the difference <span className="font-bold">
                  WorkPodium
                </span>{' '}
                makes.
              </h1>
            )}

            <form
              className="w-full max-w-xl px-4 md:px-10 mt-6"
              onSubmit={handleSubmit(onSubmit)}>
              <div className="grid gap-4 mt-2 grid-cols-1 lg:grid-cols-2">
                <Input
                  label="Full name *"
                  id="name"
                  error={errors.name}
                  errorMessage={errors.name?.message}
                  autoFocus={!comeFromLP}
                  {...register('name', {
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z\s]+$/i,
                      message: 'Provide a valid name',
                    },
                  })}
                />

                <Input
                  label="Company name *"
                  id="company_name"
                  error={errors.company_name}
                  errorMessage={errors.company_name?.message}
                  autoFocus={!!comeFromLP}
                  {...register('company_name', {required: true})}
                />
              </div>

              <div className="mt-2 grid gap-4 grid-cols-1 lg:grid-cols-2">
                <Input
                  label="Email *"
                  id="email"
                  error={errors.email}
                  errorMessage={errors.email && errors.email.message}
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Provide a valid email',
                    },
                    validate: {
                      checkUrl: async (value) =>
                        (await canUseEmail(value)) ||
                        'This email is already in use',
                    },
                  })}
                />

                <Controller
                  rules={{
                    required: true,
                    minLength: 14,
                    pattern: {
                      value: /^[^_]*$/i,
                      message: 'Provide a valid phone number',
                    },
                  }}
                  name="phone"
                  control={control}
                  render={({field}) => (
                    <MaskedInput
                      {...field}
                      label="Phone *"
                      mask="(999) 999-9999"
                      maskPlaceholder="(999) 999-9999"
                      alwaysShowMask={false}
                      removeMask={false}
                      error={errors.phone}
                      errorMessage={errors.phone ? errors.phone.message : ''}
                    />
                  )}
                />
              </div>

              <div className="mt-4 grid gap-4 grid-cols-1 lg:grid-cols-2">
                <Input
                  label="Username *"
                  id="username"
                  error={errors.username}
                  errorMessage={errors.username && errors.username.message}
                  {...register('username', {
                    required: true,
                    minLength: {
                      value: 4,
                      message: 'needs to be at least 4 characters',
                    },
                    validate: {
                      checkUrl: async (value) =>
                        (await canUseUsername(value)) ||
                        'This username is already in use',
                      whitespace: (val) =>
                        validateWhiteSpaces(val) || 'Remove all white spaces!',
                      uppercaseChar: (val) =>
                        validateUppercase(val) ||
                        'Remove all uppercase letters!',
                    },
                  })}
                />

                <Input
                  label="Password *"
                  id="password"
                  type="password"
                  error={errors.password}
                  errorMessage={errors.password && errors.password.message}
                  {...register('password', {
                    required: true,
                    minLength: {
                      value: 4,
                      message: 'needs to be at least 4 characters',
                    },
                    validate: {
                      whitespace: (val) =>
                        validateWhiteSpaces(val) || 'Remove all white spaces!',
                    },
                  })}
                />
              </div>

              <div className="mt-6">
                <ReCAPTCHA
                  sitekey="6LeRDcspAAAAAHcMxHcMKiQdXWGiEfgwnIiV16E2"
                  ref={recaptcha}
                />
              </div>

              <div className="mt-6">
                <Button
                  loading={loading}
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                  Start a trial now!
                </Button>
              </div>

              <div>
                <p className="mt-8 text-xs font-light text-center text-gray-400">
                  {' '}
                  Have an account?{' '}
                  <Link
                    to="/"
                    className="font-medium text-yellow-500 hover:text-yellow-600">
                    Login now
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
        <div className="hidden lg:flex w-5/12 lg:absolute lg:inset-y-0 lg:right-0 bg-gradient-to-b from-yellow-500 to-yellow-700">
          <img
            className="h-full w-full object-cover lg:w-full lg:h-full opacity-10"
            src={worker1}
            alt="worker"
          />
        </div>
      </div>

      {loading && <LoadingBox info="Creating your account..." />}
    </>
  );
};

export default SignUp;
