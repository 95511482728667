/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {useState, useLayoutEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useSearchParams} from 'react-router-dom';
import {LockClosedIcon} from '@heroicons/react/24/solid';

import Input from '../../../../components/_form/Input';
import Button from '../../../../components/Button';
import {useAuth} from '../../hooks/useAuth';

import logo from '../../../../assets/images/logos-inline-color.png';

const SignInPage = () => {
  const [searchParams] = useSearchParams();
  const {signIn} = useAuth();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await signIn({username: data.username, password: data.password});
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    const username = searchParams.get('username');
    const password = searchParams.get('password');
    if (searchParams && username && password) {
      onSubmit({
        username,
        password,
      });
    }
  }, [searchParams]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src={logo}
            alt="WorkPodium - Logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <Link
              to="/new-account?p=0105"
              className="font-medium text-yellow-500 hover:text-yellow-600">
              start your 30-day free trial
            </Link>
          </p>
        </div>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
            <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Input
                  label="Username"
                  id="username"
                  error={errors.username}
                  {...register('username', {required: true})}
                />
              </div>

              <div className="mt-4">
                <Input
                  label="Password"
                  id="password"
                  type="password"
                  error={errors.password}
                  {...register('password', {required: true})}
                />
              </div>

              <div className="flex w-full justify-end">
                <Link
                  to="/forgot-password"
                  className="text-xs text-gray-600 dark:text-gray-400 hover:underline">
                  Forgot Password
                </Link>
              </div>

              <div className="mt-6">
                <Button
                  loading={loading}
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-yellow-400 group-hover:text-yellow-200"
                      aria-hidden="true"
                    />
                  </span>
                  Sign in
                </Button>
              </div>
            </form>

            <p className="mt-8 text-xs font-light text-center text-gray-400">
              {' '}
              Don't have an account?{' '}
              <Link
                to="/new-account?p=workpodium"
                className="font-medium text-yellow-500 hover:text-yellow-600">
                Create One
              </Link>
            </p>
          </div>
        </div>

        {process.env.REACT_APP_CUSTOM_NODE_ENV === 'development' && (
          <div className="flex flex-col items-center">
            <h3>Accounts to test (development env)</h3>
            <div className="flex w-full">
              <Button
                extraClassName="m-2 items-center"
                onClick={() =>
                  onSubmit({username: 'admin', password: 'track12345'})
                }>
                Admin
              </Button>
              <Button
                extraClassName="m-2 items-center"
                onClick={() =>
                  onSubmit({username: 'bossae', password: '123456'})
                }>
                Boss (AE Quality)
              </Button>
              <Button
                extraClassName="m-2 items-center"
                onClick={() =>
                  onSubmit({username: 'ducicado', password: '123456'})
                }>
                Ducicado (Inactive account)
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignInPage;
